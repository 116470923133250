// Map autocomplete for that will be attached to the search form
//
document.addEventListener( 'DOMContentLoaded', function( e ) {
    // Start the call for the maps api
    createAutocompleteInstance();
	//this is to remove the focus from the input field on load
	var target = document.querySelectorAll('#input-appraisal-search-form');
    if(target) {
		for (let i = 0; i < target.length; i++) {
			target[i].blur();
		}
    }
});




// Create the variables needed to stat the autocomplete form instance
let autocomplete;
let streetAddress;


/***
 * Start the map process that calls the google maps api
 */
const createAutocompleteInstance = () => {
    let formAppraisal = document.querySelectorAll( '#appraisal-search-form');
    
    // immediatly return the user when the form apparisal is not inside the DOM
    if( ! formAppraisal ) {
        return        
    }
    for (let index = 0; index < formAppraisal.length; index++) {
        let form = formAppraisal[index];
        var formAppraisalInput = form.querySelector( '#input-appraisal-search-form' );
        // Create autocomplete instance that includes options to get the necessary information
        // from google maps
        autocomplete = new google.maps.places.Autocomplete( formAppraisalInput, {
            componentRestrictions: {country: "au"},
            fields: [
                "address_components", 
                "geometry"
            ],
            types: [
                "address"
            ]
        });
        formAppraisalInput.focus();
        autocomplete.addListener("place_changed", function() {
            fillInAddress(form,this.getPlace());
        });
    }
    
}
// const autocomplateFunction = (input) => {

// }
/**
 * Function that recieves and process the address from the google map
 */
const fillInAddress = (parent, place) => {
	let $addressUnitNum   	= parent.querySelector( '#input-address-unit-number' );
    let $addressStreetNum   = parent.querySelector( '#input-address-street-number' );
    let $addressStreetName  = parent.querySelector( '#input-address-street-name' );
    let $addressSuburb      = parent.querySelector( '#input-address-suburb' );
    let $addressState       = parent.querySelector( '#input-address-state' );
    let $addressPostcode    = parent.querySelector( '#input-address-postcode' );
    let $latitude           = parent.querySelector( '#input-address-latitude' );
    let $longitude          = parent.querySelector( '#input-address-longitude' );
    // loop results
    for (const component of place.address_components) {
        // @ts-ignore remove once typings fixed
        let componentType = component.types[0];
        
        // add the address to the model inputs
        switch( componentType ) {
            case "subpremise": {
                $addressUnitNum.value = component.short_name
                streetAddress += component.short_name;
            }
            case "street_number": {
                $addressStreetNum.value = component.short_name
                streetAddress += component.short_name;
            }
            case "route": {
                $addressStreetName.value = component.long_name
                streetAddress += component.long_name
            }

            case "locality": {
                $addressSuburb.value = component.long_name
            }

            case "administrative_area_level_1": {
                $addressState.value = component.short_name
            }

            case "postal_code": {
                $addressPostcode.value = component.long_name;
            }
        }
    }

    // Add long and lat to the model inputs
    $longitude.value  = place.geometry.location.lng();
    $latitude.value   = place.geometry.location.lat();
}