import { selectize } from '@selectize/selectize'
import { Fancybox } from '@fancyapps/ui'
import { searchSpinner, updateQueryStringParameter, getRexDocuments } from './custom-functions.js'

// selectize all select elements with default settings.
window.selectizeTheWorld = () => {

	jQuery( 'select' ).selectize(
		{
			plugins: ['remove_button', 'restore_on_backspace', 'clear_button', 'auto_position'],
			delimiter: ',',
			persist: false,
			create: function ( input ) {
				return {
					value: input, text: input,
				}
			},
		}
	)
}

// Update the sort order when clicked.
window.changePropertySortOrder = () => {

	const sortOrder = document.getElementById( 'epl-sort-listings-' );

	if ( sortOrder ) {
		jQuery( '#epl-sort-listings-' ).selectize(
			{
				onChange: function ( value, isOnInitialize ) {
					if ( value !== '' ) {
						let url    = window.location.href;
						let newUrl = updateQueryStringParameter( url, 'sortby', value );
						window.location.replace( newUrl );
					}
				}
			}
		);
	}
}

/**
 * Once the page loads, do our stuff!
 */
document.addEventListener(
	'DOMContentLoaded',
	function () {

		// Fix for the sort order tool from EPL on property archive pages.
		changePropertySortOrder();

		// Check for submit buttons and add spinner.
		searchSpinner();

		// Enables Selectize on all select elements.
		selectizeTheWorld();

		// Get Fancybox going.
		Fancybox.bind( '[data-fancybox]', {} )

		// Enable Tooltips for BootStrap 5.3.
		const tooltipTriggerList = document.querySelectorAll( '[data-bs-toggle="tooltip"]' )
		tooltipTriggerList.forEach( tooltipTriggerEl => new understrap.Tooltip( tooltipTriggerEl ) )

		// Get the contract link element, and if it exists, load the data when the dropdown is clicked.
		const documentLinks = document.getElementById( 'contract-links' );
		if ( documentLinks ) {
			documentLinks.addEventListener(
				'show.bs.dropdown',
				event => {
                getRexDocuments( documentLinks.dataset.id )
				}
			);
		}
	}
);

jQuery(function($) { 
    $("#toggle").on("click", () => {
		const body = document.body;
		body.classList.toggle("no-overflow");
		$(".fullscreen").toggleClass("active").removeClass("reverse_anim");
		const elementy = document.getElementById('toggle');
		elementy.classList.toggle("active");
	  });
  
	  $(".close").on("click", () => {
		  $(".fullscreen").toggleClass("reverse_anim");
	  });
	//single property page image masonry
	var $imagesContainer = $('.images-container');
	$imagesContainer.masonry({
		columnWidth: '.grid-sizer',
		itemSelector: '.grid-item',
		gutter: 10,
		percentPosition: true
	});
	//re init masonry layout after all images are loaded;	
	setInterval(() => {
		$imagesContainer.masonry()
	  }, 200);
})
